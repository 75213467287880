import React, { useEffect } from "react"
import { Badge, Col, Container, Row } from "reactstrap"
import { graphql } from "gatsby"
import SEO from "../components/SEO/seo"
import PropTypes from "prop-types"
import "../assets/styles/index.scss"
import ReviewsSlider from "../components/reviewsSlider"
import CTA from "../components/cta"
import Video from "../components/video"
import user from "../assets/images/user.svg"
import calendar from "../assets/images/calendar.svg"
import sandClock from "../assets/images/sand-clock.svg"
import FeatureLeft from "../components/featureLeft"
import FeatureRight from "../components/featureRight"
import "../../node_modules/react-modal-video/scss/modal-video.scss"
import Footer from "../components/footer"
import { setAffiliateId, setCoupons, setTags } from "../assets/javascript/affiliate"
import { GatsbyImage } from "gatsby-plugin-image"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { linkResolver } from "../utils/LinkResolver"
import AnchorLink from "react-anchor-link-smooth-scroll-v2"

const AffiliatePage = ({ data: { prismicAffiliate, logo } }) => {

  const features = []
  prismicAffiliate.data.features.document.data.body.map(function(featureItem, key) {
    features.push({
      imageSrc: featureItem.primary.image.localFile.childImageSharp.gatsbyImageData,
      imageAlt: featureItem.primary.image.alt,
      title: featureItem.primary.title,
      content: featureItem.primary.content.raw
    })
  })

  const featureArray = [
    {
      id: 1,
      title: "Simple",
      description:
        "Une interface colorée et intuitive pour une prise en main immédiate, et nous sommes toujours disponibles pour vous aider.",
      imgUrl: user
    },
    {
      id: 2,
      title: "Efficace",
      description:
        "Vous êtes averti à chaque échéance importante afin de toujours rester en règle avec l'administration fiscale.",
      imgUrl: calendar
    },
    {
      id: 3,
      title: "Rapide",
      description:
        "Une interface simplifiée à l'extrême pour que vous puissiez envoyer une facture en moins de 30 secondes.",
      imgUrl: sandClock
    }
  ]
  const reviews = [
    {
      id: 1,
      name: "Artee'st-shop",
      desc:
        "Bizyness est tout simplement génial pour un micro entrepreneur ! L'interface est très bien faite et simple d'utilisation. Je recommande ! je suis entièrement satisfait.",
      rating: 5
    },
    {
      id: 2,
      name: "Jonathan",
      desc:
        "RAS. Outil très utile, évolutif et Français. J'ai testé d'autres alternative sans être vraiment convaincu... Et derrière y'a un SAV dispo et à 'écoute des remarques. Je ne peux que conseillé cette app !",
      rating: 5
    },
    {
      id: 3,
      name: "Elodie",
      desc:
        "Je suis très satisfaite de ce site, je trouve cela très pratique et je gagne beaucoup de temps. Le fondateur est toujours à l'écoute et réactif quand il faut apporter des modifications.",
      rating: 5
    },
    {
      id: 4,
      name: "Myriam",
      desc:
        "Très satisfaite de Bizyness. Logiciel facile d'utilisation. Pleinement adapté aux micro-entrepreneurs. Service client très réactif. Toujours prêt à répondre aux questions.",
      rating: 5
    },
    {
      id: 5,
      name: "Pascal",
      desc:
        "Bizyness est super facile pour effectuer sa compta.nous sommes auto entrepreneur dans la mecanique. aussi nous avons un interlocuteur tres présent pour toute demande. je suis très contente et j ai renouvelé mon abonnement depuis 3 ans. Merci Bizyness",
      rating: 5
    },
    {
      id: 6,
      name: "Audrey",
      desc:
        "Une application crée pour simplifier les démarches administratives et être en règle en France. Merci car du coup on peut se consacrer à d'autres choses. Service client très réactif et à l'écoute des clients pour l'amélioration du logiciel. Je recommande",
      rating: 5
    }
  ]

  useEffect(() => {
    setAffiliateId(prismicAffiliate.data.id)
    setTags(prismicAffiliate.data.tags)
    setCoupons(prismicAffiliate.data.coupons)
  })

  return <>
    <SEO
      title={prismicAffiliate.data.seotitle} noindex={true}
    />
    <section className="bg-half-170 d-table w-100 overflow-hidden">
      <Container>
        <Row className="align-items-center pt-5">
          <Col lg="7" md="6">
            <GatsbyImage
              image={logo.childImageSharp.gatsbyImageData}
              className="d-block img-fluid"
              alt="Application de comptabilité pour entrepreneurs"
              style={{ maxWidth: "400px" }}/>
            <div className="title-heading pt-5">
              <Badge color="success" pill>
                {prismicAffiliate.data.offer}{" "}
              </Badge>
              <h1 className="heading mb-3">{prismicAffiliate.data.header}</h1>
              <h4 className="title mb-3 title-dark">{prismicAffiliate.data.title}</h4>
            </div>
            <div className="mt-4 pt-2 watch-video">
              <AnchorLink className="btn btn-primary" href="#signupcta">
                Commencez gratuitement
                <i className="mdi mdi-chevron-right"></i>
              </AnchorLink>
              <div className="mt-3">{prismicAffiliate.data.video !== null && <Video uid={prismicAffiliate.data.video}/>}</div>
            </div>
          </Col>

          <Col lg="5" md="6" className="mt-4 pt-2 mt-sm-0 pt-sm-0">
            <div className="classic-saas-image position-relative">
              <div className="bg-saas-shape position-relative">
                <GatsbyImage
                  image={prismicAffiliate.data.image.localFile.childImageSharp.gatsbyImageData}
                  className="mx-auto d-block img-affiliate"
                  alt={prismicAffiliate.data.image}/>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
    <section>
      <Container className="mt-4">
        <Row>
          {featureArray.map((feat, i) => {
            return (
              <Col md={4} xs={12} key={i} name="featurebox">
                <div className={"features text-center"}>
                  <div className="image position-relative d-inline-block mt-4 mt-md-0">
                    <img
                      src={feat.imgUrl}
                      className="avatar avatar-small"
                      alt=""
                    />
                  </div>
                  <div className="content mt-4">
                    <h4 className="title-2">{feat.title}</h4>
                    <p className="text-muted mb-0">{feat.description}</p>
                  </div>
                </div>
              </Col>
            )
          })}
        </Row>
      </Container>
    </section>
    <section className="section overflow-hidden">

      {features.map((featureItem, key) =>
        <Container className={`${key > 0 ? "mt-100 mt-60" : "pb-5 mb-md-5"}`}>
          {key % 2 === 0 ? <FeatureLeft featureDetails={featureItem}/> :
            <FeatureRight featureDetails={featureItem}/>}
        </Container>
      )}

      <CTA id={"affiliate"} affiliateId={prismicAffiliate.data.id} tagsId={prismicAffiliate.data.tags}
           couponsId={prismicAffiliate.data.coupons}/>

      <Container className="mt-100 mt-60">
        <Row className="justify-content-center">
          <Col xs="12" className="text-center">
            <div className="section-title mb-4 pb-2">
              <h4 className="title mb-4">
                Vous n'êtes pas convaincu ?
              </h4>
              <p className="text-muted para-desc mx-auto mb-0">
                Les utilisateurs de{" "}
                <span className="text-primary font-weight-bold">
                    Bizyness
                  </span>{" "}
                en parlent mieux que nous. Vous n'avez plus d'excuse pour ne
                pas nous rejoindre !
              </p>
            </div>
          </Col>
        </Row>

        <ReviewsSlider reviews={reviews} colclassName="mt-4"/>
      </Container>

    </section>
    <Footer/>
  </>
}

AffiliatePage.propTypes = {
  data: PropTypes.shape({
    prismicAffiliate: PropTypes.object.isRequired,
    logo: PropTypes.object
  }).isRequired
}

export default withPrismicPreview(AffiliatePage, [
  {
    repositoryName: process.env.PRISMIC_REPOSITORY_NAME,
    linkResolver
  }
])

export const pageQuery = graphql`
  query AffiliateQuery($id: String) {
    site {
      siteMetadata {
        apiUrl
      }
    }
    prismicAffiliate(id: { eq: $id }) {
      _previewable
      uid
      data {
        coupons
        id
        header
        offer
        tags
        description
        title
        seotitle
        video
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 90, placeholder: NONE, layout: FULL_WIDTH)
            }
          }
          alt
        }
        features {
          document {
            ... on PrismicFeaturesGroup {
              id
              data {
                body {
                  ... on PrismicFeaturesGroupDataBodyFeature {
                    primary {
                      title
                      content {
                        raw
                      }
                      image {
                        localFile {
                          childImageSharp {
                            gatsbyImageData(width: 800, quality: 90, placeholder: NONE, layout: CONSTRAINED)
                          }
                        }
                        alt
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    logo: file(relativePath: {eq: "logo-dark-big.png"}) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 90, placeholder: NONE, layout: CONSTRAINED)
      }
    }
  }
`